@tailwind base;
@tailwind components;
@tailwind utilities;


* {
  box-sizing: border-box;
  min-width: 0;
  min-height: 0;
}


@layer components {
  
  .error {
    @apply bg-arkanic-red text-arkanic-popblue
  };
  
  .errorLink {
    @apply hover:text-arkanic-popgreen
  };
  
  .errortext {
    @apply text-arkanic-red
  };

  .blueback {
    @apply bg-arkanic-blue text-arkanic-popgreen
  };
  
  .button {
    @apply bg-arkanic-popgreen text-arkanic-blue
  };
  
  .greenback {
    @apply bg-arkanic-lightgreen text-arkanic-blue
  };

  .text {
    @apply text-3xl font-bold text-center;
  };
  
  .navbar {
    @apply bg-arkanic-blue
  };

  .navlink {
    @apply text-arkanic-popblue text-center hover:activeNavlink
  }
  
  .activeNavlink {
    @apply text-arkanic-popgreen
  }

  .centralise {
    @apply w-full h-full flex flex-col overflow-hidden
    justify-evenly items-center;
  }

  .centralrow {
    @apply w-full flex flex-row overflow-hidden
    justify-evenly items-center
  }

  .logoutButton {
    @apply bg-arkanic-red text-arkanic-lightgreen p-5 rounded-full
  }

  .editButton {
    @apply bg-arkanic-blue text-arkanic-lightgreen p-5 rounded-full
  }

}

/* 


Colours list
#11cc11
#111177
#1177ff
#11ccff
#ccffcc


*/


/* 
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */
